<template>

<app-page theme="white" back-button="Recommendation">

	<app-title :text="content.title" :is-dark="true" :is-uppercase="true" is-restricted="true" />

	<app-content class="survey-content">

		<div v-html="content.text" class="survey-content-text" />

		<com-columns v-if="!window.is.mobile" />

		<com-question v-for="item in content.items" :key="item.key" :value="answers[item.key]" :question="item" v-on:click="onQuestionClick" />

		<div class="survey-other">

			<h3>Other things I want to ask</h3>

			<textarea v-model="other"></textarea>

		</div>

		<com-foot />

		<app-button text="Complete" theme="dark" v-on:click="onSaveClick" class="survey-button" />

	</app-content>

</app-page>

</template>

<script>

export default {

	components: {
		'com-columns': () => import('./recommendation-survey/Columns'),
		'com-question': () => import('./recommendation-survey/Question'),
		'com-foot': () => import('./recommendation/Foot')
	},

	data: function() {

		return {
			other: ''
		}

	},

	created: function() {

		this.other = this.answers.other

	},

	computed: {

		content: function() {

			return this.$store.getters['data'].info.survey

		},

		answers: function() {
			
			return this.$store.getters['survey']

		}

	},

	watch: {

		other: function(n) {

			this.$store.commit('survey', {
				name: 'other',
				value: n
			})

		}

	},

	methods: {

		onQuestionClick: function(e) {

			this.$store.commit('survey', e)

		},

		onSaveClick: function() {

			this.$store.commit('completed', 'survey')

			this.$router.push({
				name: 'Recommendation'
			})

		}

	}

}

</script>

<style scoped>

.is-mobile .survey-content >>> .content-inner {
	padding-top: 0px;
}

.is-mobile .survey-content-text {
	padding-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
}

.survey-other h3 {
	color: #312D50;
	font-size: 24px;
	line-height: 29px;
	font-weight: 400;
	padding: 20px;
}

.survey-other textarea {
	background-color: #F0F0F0;
	font-size: 18px;
	line-height: 22px;
	color: #202020;
	border-radius: 28px;
	padding: 20px;
	width: 100%!important;
	height: 230px!important;
}

.is-mobile .survey-other h3 {
	padding: 20px 0px;
}

.survey-button {
	margin-top: 80px;
}

.is-mobile .survey-button {
	margin-top: 40px;
}

</style>
